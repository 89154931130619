.player-count-container {
    display: flex;
    background-color: #e8e8e8; /* Adjust the color to match your needs */
    border-radius: 40vw; /* Rounded corners */
    padding: 10px;
    max-width: 300px;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}

.player-count-button {
    width: 40px; /* Width of the button */
    height: 40px; /* Height of the button */
    line-height: 40px; /* Center the text vertically */
    background-color: #fff;
    border: palegoldenrod;
    border-radius: 50%; /* Make it a circle */
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effect and transform */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.player-count-button:hover {
    background-color: white;
    transform: scale(1.1); /* Enlarge the button */
}

.player-count-button.active {
    background-color: var(--green-colour); /* Active color */
    color: #fff; /* White text on active button */
    transform: scale(1.1);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
}

.player-count-label {
    margin-bottom: 16px;
}
