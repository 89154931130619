.SP1-court-frame--top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: content-box;
  width: 170px;
  padding: 20px 10px 20px 20px;
  align-items: center;
  gap: 30px;
  border: 1px rgb(211, 124, 124);
  background-color: rgba(1, 211, 140, 0.33);
  border-radius: 10px 10px 0 0;
}

.SP1-court-frame--mid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: content-box;
  width: 230px;
  padding: 20px 10px 20px 20px;
  align-items: center;
  gap: 30px;
  border: 1px rgb(211, 124, 124);
  background-color: rgba(1, 211, 140, 0.33);
  border-radius: 0 10px 0 0;
}

.SP1-court-frame--bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: content-box;
  width: 290px;
  padding: 15px 10px 15px 20px;
  align-items: center;
  gap: 30px;
  border: 1px rgb(211, 124, 124);
  background-color: rgba(1, 211, 140, 0.33);
  border-radius: 0 10px 10px 10px;
}