.start-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100%; /* Make sure it takes at least the full height of the viewport */
  max-width: 100%;
}

.start-page-container > * {
  /* < selector targets children of this container and recursively applies styles to them*/
  margin-bottom: 30px;

}

.start-button {
  background-color: #01d38c; /*--green-colour*/
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.input-field {
  border-radius: 10px;
  text-align: center;
  height:42px;
  width: 225px;
  border-color: var(--green-colour);
}
