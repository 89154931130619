
.QE-court-frame--top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: content-box;
    width: 160px;
    padding: 20px 20px 20px 20px;
    gap: 15px;
    border: 1px rgb(211, 124, 124);
    background-color: rgba(1,211,140,0.33);
    border-radius: 10px 10px 0 0;
  }
  
  .QE-court-frame--bottom {
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 10px;
    justify-content: flex-start;
    border: 1px white;
    padding: 15px;
    gap: 13px;
    background-color: rgba(1,211,140,0.33);
    border-radius: 0 10px 10px 10px;
  }