.queue-button {
    background-color: var(--green-colour); /* The green background color */
    color: white; /* Text color */
    border: none; /* No border */
    border-radius: 50%; /* Circular shape */
    width: 100px; /* Width of the button */
    height: 100px; /* Height of the button */
    font-size: 2.3rem; /* Adjust the font size as needed */
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 8px 35px rgba(2, 25, 14, 0.5); /* horizontal-offset vertical-offset blur-radius color */
    box-shadow: 3px 3px 35px rgba(2, 252, 56, 0.5), inset 0 -3px 5px rgba(119, 255, 109, 0.2), inset 0 3px 5px rgba(255, 255, 255, 0.8);
}

.queue-button:hover {
  transform: scale(1.08);
  transition: 0.01ms;
  background-color: orange;
}