.KT-court-frame--top {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: content-box;
    width: 240px;
    padding: 20px 20px 20px 20px;
    align-items: center;
    gap: 20px;
    border: 1px rgb(211, 124, 124);
    background-color: rgba(1, 211, 140, 0.33);
    border-radius: 10px 10px 0 0;
  }

.KT-court-frame--bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: content-box;
    width: 300px;
    padding: 20px 20px 30px 20px;
    align-items: center;
    gap: 20px;
    border: 1px rgb(211, 124, 124);
    background-color: rgba(1, 211, 140, 0.33);
    border-radius: 0 10px 10px 10px;
}

  /* Remove margin from the last button */
  .court-button:last-child {
    margin-bottom: 0;
  }

  