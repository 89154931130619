body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --green-colour: #01D38C;
  --red-colour: #F56D50;
  --dark-colour: #221F20;
  --grey-colour: rgb(200, 200, 200);
  --orange-colour: rgba(245, 109, 80, 1);
  --blue-color: #10A9FF;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* This ensures that padding and border sizes are included in the total width and height of elements */
}

body {
  margin: 0;
  font-family: "Source Sans 3";
}

h2,h3 {
  margin: 0;
  font-family: "Montserrat";
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  margin-top: 20px;
  width: 350px;
  text-wrap: wrap;
  text-align: center;
  line-height: 14px;
  font-weight: 400;
}

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
}

/* Court Buttons */
.available {
  background-color: var(--blue-color);
}

.unavailable {
  background-color: darkslategrey;
}

.selected {
  background-color: rgb(255, 174, 12);
  color: black;
}

.court-button {
  height: 30px; /* Figma: height = 28px, width = 60px */
  width: 70px;
  cursor: pointer;
  border-radius: 7px;
  border: 4px solid white;
  flex-shrink: 0;
  font-family: "Monserrat", sans-serif;
  color: white;
  font-size: small;
}

.court-button--rotated {
  height: 70px;
  width: 30px;
}

.court-button:hover:enabled,
.court-button--rotated:hover:enabled {
  transition: 0.01ms;
  transform: scale(1.05);
}

