.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: min-content;
}

/* CARD 1 STYLING */
.carousel-item-container--one {
  display: flex;
  flex-direction: row;
  align-items: right;
  height: 410px; /* Fill the height of the parent container */
  width: 344px;
  background-image: url("../../../../public/assets/onboarding-background.png");
  background-size: cover;
}

.overlay-person1-image {
  height: auto;
  max-width: 230px;
  top: 160px;
  padding-left: 5px;
  padding-right: 10px;
}

.carousel-container-caption--one {
  display: inline-block;
  padding-left: 150px; /* Add padding to the right */
}

.carousel-container-caption--h3 {
  display: flex;
  font-size: large;
  text-align: left;
  z-index: 10;
  width: 60px;
}

.carousel-container-caption--p {
  display: flex;
  font-size: small;
  text-align: left;
  z-index: 10;
  width: 120px;
}

/* CARD 2 STYLING */
.carousel-item-container--two {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 410px; /* Fill the height of the parent container */
  width: 344px;
  background-image: url("../../../../public/assets/onboarding-background.png");
  background-size: cover;
}

.overlay-person2-image {
  width: 300px;
  rotate: -5deg;
}

.carousel-container-caption--two {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  left: 0;
}

.carousel-container-caption--two.carousel-caption {
  padding: 0px;
  left: 0;
  position: relative;
}

.carousel-container-caption--h3wide {
  display: flex;
  font-size: large;
  text-align: center;
  padding: 0;
}

.carousel-container-caption--pwide {
  display: flex;
  font-size: small;
  text-align: center;
  z-index: 10;
  width: 100%;
}

/* CARD 3 STYLING */

.carousel-item-container--three {
  display: flex;
  flex-direction: row;
  align-items: right;
  height: 410px; /* Fill the height of the parent container */
  width: 344px;
  background-image: url("../../../../public/assets/onboarding-background.png");
  background-size: cover;
}

.carousel-container-caption--three {
}

.overlay-person3-1-image {
  position: absolute;
  left: 140px;
  top: 60px;
  width: 240px;
  rotate: -20deg;
}
