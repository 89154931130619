.popup-alert-icon {
    margin: 0 auto;
}

.modal {
    display: block;
    position: fixed;
    width: 100vw;
}

.modal-overlay {
    width: 100%;
    height: inherit;
    background-color: #000000;
    opacity: 0.7;
}

.modal-content {
    border: none;
}

.modal-dialog {
    position: fixed;
    margin: 0;
    width: 326px;
    align-self: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 18px 0 18px;
}
