.modal-body p {
    width: fit-content;
    padding: 0 50px;
    text-align: center;
    line-height: 32px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    gap: 13px;
    border-top: none;
    padding-bottom: 42px;
}

.primaryCta,
.secondaryCta {
    width: 40%;
    height: 49px;
    border-radius: 10px;
}

.primaryCta,
.primaryCta:active,
.primaryCta:hover {
    background-color: #01d38c !important;
    color: #ffffff;
    border: none;
}

.secondaryCta,
.secondaryCta:active,
.secondaryCta:hover {
    background-color: #f56d50 !important;
    color: #ffffff;
    border: none;
}
